import {FC} from "react";
import {IFModal} from "../interfaces/elements/IFModal";
import {Close} from "@mui/icons-material";

export const FModal: FC<IFModal> = ({children, className, title, canClose, onClose}) => {
    return(
        <div className="modal_container">
            <div className={`modal_content ${className} !mr-3 !ml-3`}>
                <div className="text-2xl h-14 m-auto w-full bg-fab_congress-blue-600 text-white flex align-middle">
                    <div className="flex-1"><p className="mt-3.5 ml-3.5 text-left">{title}</p></div>
                    {canClose ?
                        <button onClick={onClose}><Close
                            className={"w-24 mr-3.5 hover:fill-red-500 cursor-pointer"}/></button>
                        :
                        <></>
                    }
                </div>
                {children}
            </div>
        </div>)
}