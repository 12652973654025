import './App.css';
import FabAppProvider from './contexts/FabAppProvider';
import {Routes, Route, HashRouter} from 'react-router-dom';
import {AuthenticatedRoute} from './components/base/layout/AuthenticatedRoute';
import {Dashboard, DashBoardPage} from './components/base/pages/Dashboard';
import {Login} from './components/base/pages/Login';
import {NotFound} from './components/base/pages/404';
import {CompaniesPage} from "./components/base/pages/CompaniesPage";
import {SitesPage} from "./components/base/pages/SitesPage";
import {RolesPage} from "./components/base/pages/RolesPage";
import {UsersPage} from "./components/base/pages/UsersPage";
import {SiteMovementsPage} from "./components/base/pages/SiteMovementsPage";
import {ApplicationDevicesPage} from "./components/base/pages/ApplicationDevicesPage";
import {ContainerTypesPage} from "./components/base/pages/ContainerTypesPage";
import {ProductCategoriesPage} from "./components/base/pages/ProductCategoriesPage";
import {ProductReferencesPage} from "./components/base/pages/ProductReferencesPage";
import {CustomerPage} from "./components/base/pages/CustomerPage";
import {EncodingPage} from "./components/reco/pages/EncodingPage";
import {GlobalApiProvider} from "./contexts/GlobalApiProvider"
import { TagInformationsPage } from "./components/base/pages/TagInformationsPage";
function App() {
    // @ts-ignore
    const basename = window.config.REACT_APP_BASENAME ?? '/';
    return (
        <FabAppProvider>
            <GlobalApiProvider>
            <div className="flex w-full h-screen">
                <HashRouter basename={basename}>
                    <div className="w-full">
                        <Routes>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/*" element={<NotFound/>}/>
                            <Route path="/login" element={<Login/>}/>
                                <Route path="/dashboard"
                                       element={
                                           <AuthenticatedRoute element={<Dashboard/>}/>
                                       }>
                                    <Route path="" element={<AuthenticatedRoute element={<DashBoardPage/>}/>}/>
                                    <Route path="*"                                 element={<NotFound/>}/>
                                    <Route path="encoding"
                                           element={<AuthenticatedRoute element={<EncodingPage/>}/>}/>
                                    <Route path="encoding/:id"
                                           element={<AuthenticatedRoute element={<></>}/>}/>
                                    <Route path="companies"
                                           element={<AuthenticatedRoute admin superUser element={<CompaniesPage/>}/>}/>
                                    <Route path="sites"
                                           element={<AuthenticatedRoute admin superUser element={<SitesPage/>}/>}/>
                                    <Route path="customers"
                                           element={<AuthenticatedRoute admin superUser element={<CustomerPage/>}/>}/>
                                    <Route path="movements"
                                           element={<AuthenticatedRoute element={<SiteMovementsPage/>}/>}/>
                                    <Route path="roles"
                                           element={<AuthenticatedRoute admin superUser element={<RolesPage/>}/>}/>
                                    <Route path="users"
                                           element={<AuthenticatedRoute admin superUser element={<UsersPage/>}/>}/>
                                    <Route path="product_categories"
                                           element={<AuthenticatedRoute admin superUser element={<ProductCategoriesPage />}/>}/>
                                    <Route path="product_references"
                                           element={<AuthenticatedRoute admin superUser  element={<ProductReferencesPage />}/>}/>
                                    {/*<Route path="taginfos"*/}
                                    {/*       element={<AuthenticatedRoute superUser element={<TagInformationsPage />}/>}/>*/}
                                    <Route path="devices"
                                           element={<AuthenticatedRoute superUser element={<ApplicationDevicesPage />}/>}/>
                                    <Route path="container_types"
                                           element={<AuthenticatedRoute superUser element={<ContainerTypesPage />}/>}/>
                                </Route>
                        </Routes>
                    </div>
                </HashRouter>
            </div>
            </GlobalApiProvider>
        </FabAppProvider>
    );
}

export default App;
