import React, {ReactElement, useEffect, useState} from "react";
import { useFabApp } from "../../../hooks/useFabApp";
import { Login } from "../pages/Login";
import {useNavigate} from "react-router-dom";

type AuthenticatedRouteProps = {
    element: ReactElement
    admin?: boolean
    superUser?: boolean
  };
  
export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ element, admin, superUser }) => {
  const { isAuthenticated, user} = useFabApp();
  const [validElement, setValidElement] = useState<ReactElement>();
  const navigate = useNavigate()
    useEffect(() => {
        if (isAuthenticated) {
            if (admin) {
                user?.Role?.adminAccess ? setValidElement(element) : navigate('/dashboard')
                return
            }
            if (superUser) {
                user?.superUser ? setValidElement(element) : navigate('/dashboard')
                return
            }
            setValidElement(element)
        } else setValidElement(<Login/>)
    }, [isAuthenticated, navigate]);
  return (
      <>{validElement}</>
  );
};
  