import { IApiReturnDefault } from "../interfaces/IApiFuncs";
import {BASE_POST_OPTIONS, BASE_URL} from "./static_vars";

export async function verifiyToken(token: string): Promise<IApiReturnDefault> {
    const opts = BASE_POST_OPTIONS;
    opts.body = new FormData();
    // opts.body.append('token', token);
    opts.headers= new Headers()
    opts.headers.append("Authorization", `Bearer ${token}`)
    const result = await fetch(BASE_URL+"auth/verify_credentials", opts).then( async (resp) => {
        if (resp.status === 200) {
            let newUser = await resp.json()
            return {
                success: true,
                message: "Connexion réussie",
                data: null,
                user: newUser
            };
        } else {
            return {
                success: false,
                message: resp.statusText ?? "Connexion échouée",
                user: null
            };
        }
    }).catch( (e: any) => {
        return {
            success: false,
            message: "Connexion échouée - " + e.message,
            user: null
        };
    });
    return Promise.resolve(result);
}
export async function getToken(login: string, password: string): Promise<IApiReturnDefault>{
    const opts = BASE_POST_OPTIONS;
    opts.body = new FormData();
    opts.body.append('login', login);
    opts.body.append('password', password);
    const result = await fetch(BASE_URL+"auth/login", opts).then( async (resp) => {
        if (resp.status === 200) {
            let data = await resp.json();
            return {
                success: true,
                message: "Connexion réussie",
                data: data
            };
        } else {
            return {
                success: false,
                message: resp.statusText?? "Connexion échouée"
            };
        }
    }).catch( (e: any) => {
        return {
            success: false,
            message: "Connexion échouée - " + e.message
        };
    });
    return Promise.resolve(result);
}