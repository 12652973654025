import React, { ReactNode, useRef, useState } from 'react';
import {KeyboardArrowRight} from '@mui/icons-material';

export const NavSection: React.FC<{ title: string; children: ReactNode[] }> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="rounded-md-full flex p-1 cursor-pointer" onClick={toggleOpen}>
                <KeyboardArrowRight
                    className={`transform transition-transform duration-100 ${isOpen ? 'rotate-90' : ''}`}
                />
                <span className="text-md font-bold text-fab_congress-blue-500">{title}</span>
            </div>
            <div
                ref={contentRef}
                style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0' }}
                className={`transition-max-height duration-500 ease-in-out overflow-hidden`}
            >
                {children}
            </div>
        </>
    );
};
