import '../fab_ui.css'
import React, {FC, useEffect, useState} from 'react';
import {IButton} from "../interfaces/inputs/IButton";
import {useNavigate} from "react-router-dom";
import {useFabApp} from "../../../hooks/useFabApp";
export const FDashButton:
    FC<{processType?: number, processId?: number, topText?: string} & IButton> =
    ({processType, processId, text, leftIcon, onClick, route, topText, className}) => {
        const navigate = useNavigate();
        const [active, setActive] = useState<boolean>();
        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (onClick) {
                onClick(e);
            }
            if (route) {
                navigate(route);
            }
        }
        useEffect(() => {
            if (route) {
                const routeWithoutSlashes = route.replace(/\//g, ''); // Retirer tous les '/'
                const urlWithoutSlashes = document.URL.replace(/\//g, ''); // Retirer tous les '/'
                const regex = new RegExp(`${routeWithoutSlashes}($|[^a-zA-Z0-9_])`);
                const match = urlWithoutSlashes.match(regex);
                if (match != null) {
                    setActive(true)
                    return
                }
            }
            setActive(false)
        }, [navigate]);
        return (
            <button
                onClick={handleClick}
                className={`fab_dashboard_element ${className}`}>
                    {leftIcon}
                    {text}
            </button>
        )
    }