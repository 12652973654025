import React, {FC} from "react";
import {IFCheckBox} from "../interfaces/inputs/IFCheckBox";

export const FCheckBox: FC<IFCheckBox> = ({label, state,data, onChange, onVoidChange, className, border, dataCustom}) => {
    return(
        <div className={`inline-flex items-center rounded-lg border-transparent ${className} ${border ? 'border-2' : ''} ${border && state ? `!border-fab_mint_green-200` : ''}`}>
            <label className="relative flex items-center p-1 rounded-full cursor-pointer"
                   htmlFor="customStyle">
                <input type="checkbox"
                       checked={state}
                       data-custom={dataCustom}
                       className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none  border border-gray-900/20 bg-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-white before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-white checked:before:bg-white hover:before:opacity-0"
                       id="customStyle" onChange={onChange ?? onVoidChange} />
                <span
                    className="absolute transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="green"
                                         stroke-width="0">
                                      <path
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            ></path>
                                    </svg>
                                </span>
            </label>
            {label && <p> {label} </p>}
        </div>
    )
}