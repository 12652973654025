import React, {MouseEventHandler, useEffect, useState} from "react"
import {Button} from "../../ui/inputs/Button"
import {ArrowPathIcon, PlusCircleIcon} from "@heroicons/react/24/solid"
import {IFModal} from "../../ui/interfaces/elements/IFModal"
import {FModal} from "../../ui/elements/FModal"

interface ITablePageLayoutProps {
    title: string,
    Editor?: React.FC<IFModal>,
    checkboxs?: React.ReactNode,
    forceRefreshData: () => void,
    errorReference: Error | null,
    resetError: () => void,
    loadingReference: boolean,
    children: React.ReactNode,
}

export const TablePageLayout: React.FC<ITablePageLayoutProps> = ({
    title,
    Editor,
    checkboxs,
    forceRefreshData,
    children,
    errorReference,
    resetError,
    loadingReference
    }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const onCloseModal: MouseEventHandler<HTMLButtonElement> = (e) => {
        setModalOpen(false)
    }
    const openModal = () => {
        setModalOpen(true)
    }
    return (<>
        <div className="w-full h-1/6">
            <div className="panel_page_entete h-1/3">
                <span className="text-2xl m-auto">{title}</span>
            </div>
            <div className="flex w-full justify-around m-auto h-1/3">
                {Editor && <Button onClick={openModal} className={"w-1/3"} text={`Ajouter un(e) ${title.toLowerCase()}`}
                                   leftIcon={<PlusCircleIcon/>}/>}
                <Button onClick={() => forceRefreshData()} loading={loadingReference} className={"w-1/3"}
                        text={"Rafraichir"}
                        leftIcon={<ArrowPathIcon/>}/>
            </div>
            <div className="w-1/2 flex justify-around items-center m-auto mt-2 mb-2 bg-white h-1/3">
                {checkboxs}
            </div>
        </div>
        {children}
        {errorReference && !modalOpen &&
            <FModal
                className="bg-white w-3/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl !mt-5"
                canClose onClose={resetError} title={"Erreurs"}>
                <p className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{errorReference.message}</p>
            </FModal>}
        {
            Editor && modalOpen && <Editor onClose={onCloseModal} setModalOpen={setModalOpen}/>
        }

    </>)
}