import {NavBar} from "../layout/NavBar";
import {Outlet} from "react-router-dom";
import FabEntete from "../../../assets/Entete.png";
import React from "react";
import {ReactComponent as PalletSvg} from "../../../assets/pallet_512dp_000000_FILL0_wght400_GRAD0_opsz48.svg"
import {ReactComponent as Package2} from "../../../assets/package_2_512dp_000000_FILL0_wght400_GRAD0_opsz48.svg"
import {ReactComponent as PrecisionManufacturingSvg} from "../../../assets/precision_manufacturing_512dp_000000_FILL0_wght400_GRAD0_opsz48.svg"
import {ReactComponent as LeakAddSvg} from "../../../assets/leak_add.svg"
import {useDashboardApi} from "../../../api/base_apis";
import {
    ArrowPathIcon,
    ArrowsRightLeftIcon,
    CalendarDaysIcon
} from "@heroicons/react/24/solid"
import {FDashButton} from "../../ui/inputs/FDashButton";
import {
    Check,
    HourglassEmpty,
    Inventory, LeakAdd,
    LocalShipping,
    Rule, TapAndPlay
} from "@mui/icons-material";
import {Button} from "../../ui/inputs/Button"


export const DashBoardPage = () => {
    const {data: dashboards, loading, forceRefresh} = useDashboardApi();
    return (
        <div className="w-full h-full bg-fab_app_container flex flex-col">
            <div className="panel_page_entete">
                <span className="text-2xl m-auto">TABLEAU DE BORD</span>
            </div>
            <Button onClick={() => forceRefresh()} loading={loading} className={"w-1/3"}
                    text={"Rafraichir"}
                    leftIcon={<ArrowPathIcon/>}/>
            {
                dashboards.length > 0 && <>
                    <div className={"w-full p-5 h-48 flex gap-5 justify-around"}>
                        <FDashButton
                            leftIcon={<CalendarDaysIcon
                                className={"h-24"}/>
                            }
                            route={'movements'}
                            className={"w-1/2"}
                            topText={"Cette semaine"}
                            text={dashboards[0].countMovementsInWeek + " Mouvements cette semaine"}
                        />
                        <FDashButton
                            leftIcon={<ArrowsRightLeftIcon
                                className={"h-24"}/>
                            }
                            route={'movements'}
                            topText={"Au Total"}
                            className={"w-1/2"}
                            text={dashboards[0].countAllMovements + " Mouvements au total"}
                        />
                    </div>
                    <div className="w-11/12 h-2/3 flex gap-2 m-auto flex-wrap">
                        <div
                            className="w-[49%] m-auto h-1/4 flex bg-gray-200 shadow-xl border-2 rounded border-gray-500 text-center hover:border-fab_congress-blue-500 text-lg">
                            <div className="w-full h-full flex items-center">
                                <div className="w-1/2 h-full text-center m-auto flex flex-col">
                                    <LeakAddSvg className={"h-full m-auto"}/>
                                    <span className={"text-2xl"}>{dashboards[0].countProdOrder.all} Ordres d'encodage</span>
                                </div>
                                <div className="w-1/2 mr-5 h-full flex flex-col justify-center text-right">
                                    <span className={"flex justify-around "}><HourglassEmpty className={"w-1/3"}/> <span
                                        className={"text-xl w-2/3 text-left"}>{dashboards[0].countProdOrder.waiting} Ordres en attente</span></span>
                                    <span className={"flex justify-around"}><TapAndPlay className={"w-1/3"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countProdOrder.started} Ordres en cours</span></span>
                                    <span className={"flex justify-around"}><Check className={"w-1/3"}/> <span
                                        className={"w-2/3 text-xl text-left"}>{dashboards[0].countProdOrder.done} Ordres cloturés</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
export const Dashboard = () => {
    return (
        <>
            <div className="w-full h-full flex">
                <NavBar/>
                <div className="w-10/12 h-full flex flex-col">
                    <div className="w-full flex h-16 bg-fab_congress-blue-800">
                        {/*<img alt="Logo RECO" src={LogoReco}/>*/}
                        <img alt="Logo FabTracer" src={FabEntete} className="w-full h-full"/>
                    </div>
                    <div className="w-full h-full bg-fab_app_container flex flex-col">
                        <Outlet/>
                    </div>
                </div>

                {/*<NotificationsBar/>*/}
            </div>
        </>
    );
};