import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel, ValueOptions} from "@mui/x-data-grid"
import {FDataGrid} from "../../ui/elements/FDataGrid"
import {ProductReferenceEditor} from "./editors/ProductReferenceEditor"
import {TablePageLayout} from "../layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"

export const ProductReferencesPage = () => {
    // const {data: containerTypes, getItemById: getContainerType} = useContainerTypesApi()
    const {
        productCategoriesApi: {data: productCategories, getItemById: getProductCategory},
        productReferencesApi: {
            forceRefresh, data:
            productReferences, loading:
            loadingReferences, error:
            error, resetApiError, updateItem:
            updateReference, deleteItem:
            deleteReference, refreshItems:
            refreshReferences
        },
        containerTypesApi: {data: containerTypes}
    } = useGlobalApi()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [modalCreateProductReference, setModalCreateProductReference] = useState<boolean>(false)
    const [columns, setcolumns] = useState<GridColDef[]>([
        {field: 'id', headerName: '#', width: 50},
        {field: 'label', headerName: 'Nom', width: 150, editable: true},
        {field: 'code', headerName: 'Référence', width: 150, editable: true},
        {field: 'category', headerName: 'Catégorie', width: 150, editable: true},
        {field: 'container', headerName: 'Conditionnement', width: 150, editable: true},
        {field: 'containerUnity', headerName: 'Quantité conditionnement', width: 150, editable: true},
    ])

    useEffect(() => {
        // if (containerTypes.length == 0) return
        if (productCategories.length == 0) return
        let selectOptsContainerTypes: ValueOptions[] = []
        containerTypes.map(type => {
            selectOptsContainerTypes.push({label: type.label, value: type.id})
        })
        let selectOptsProductCategories: ValueOptions[] = []
        productCategories.map(category => {
            selectOptsProductCategories.push({label: category.label, value: category.id})
        })
        setcolumns([
            {field: 'id', headerName: '#', width: 50},
            {field: 'label', headerName: 'Nom', width: 150, editable: true},
            {field: 'code', headerName: 'Référence', width: 150, editable: true},
            {
                field: 'productCategoryId',
                type: 'singleSelect',
                headerName: 'Catégorie',
                width: 150,
                editable: true,
                valueOptions: selectOptsProductCategories
            },
            {field: 'containerTypeId', type:'singleSelect', headerName: 'Conditionnement', width: 150, editable: false, valueOptions: selectOptsContainerTypes},
            {field: 'containerUnity', headerName: 'Quantité conditionnement', width: 200, editable: false},
        ])
    }, [productCategories])

    const openModalCreateProductReference = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!modalCreateProductReference) setModalCreateProductReference(true)
    }
    const onCloseModal = (e: React.MouseEvent) => {
        setModalCreateProductReference(false)
        refreshReferences().then()
    }
    const resetError = () => {
        resetApiError()
    }
    useEffect(() => {
        if (!modalCreateProductReference) refreshReferences().then()
    }, [modalCreateProductReference])
    return (
        <>
            <TablePageLayout
                title={"RÉFÉRENCE PRODUIT"}
                Editor={ProductReferenceEditor}
                forceRefreshData={forceRefresh}
                errorReference={error}
                resetError={resetApiError}
                loadingReference={loadingReferences}
            >
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    updateRow={updateReference}
                    deleteRow={deleteReference}
                    rows={productReferences}
                    loading={loadingReferences}
                    columns={columns}
                    confirmDeleteText={"Attention, la suppression d'une référence entraine la perte de tout les produits"}
                />
            </TablePageLayout>
        </>
    )
}