import {ArrowPathIcon} from '@heroicons/react/24/solid';
import '../fab_ui.css'
import {FC} from 'react'
import {IButton} from "../interfaces/inputs/IButton";
export const Button:
    FC<IButton> =
    ({id, text, leftIcon, soloIcon, onClick, round, fullStyle, className, specificColor, disabled, loading}) => {
    return(
        <div className={`flex align-middle m-auto ${className} `}>
            <button
                id={id}

                disabled={disabled || loading}
                className={`w-full fab_button ${fullStyle ? "button_full" : "button_light"} ${specificColor ?? ""} ${disabled ? "!bg-gray-500 ": ""} ${round ? "rounded-full" : ""} flex justify-center ${loading ? "!bg-fab_mint_green-500 !text-gray-500" : ""}`}
                type="submit"
                onClick={onClick}>
                {leftIcon &&
                    loading &&
                        <div className="w-12 m-auto">{<ArrowPathIcon className={"animate-spin"}/>}</div>
                    || leftIcon &&
                        <div className="w-12 p-1 m-auto">{leftIcon}</div>
                }
                {text &&
                    loading &&
                        <span className="w-full m-auto text-center p-2">Chargement</span>
                    ||
                        <span className={`w-full m-auto text-center p-2`}>{text}</span>
                }
                {soloIcon &&
                    loading &&
                        <div className="w-12 m-auto">{<ArrowPathIcon className={"animate-spin"}/>}</div>
                    || soloIcon &&
                        <span className="m-auto">{soloIcon}</span>
                }
            </button>

        </div>

    )
}