import React, {useState} from "react";
import {GridRowModesModel} from "@mui/x-data-grid";
import {FDataGrid} from "../../ui/elements/FDataGrid";
import {ContainerTypeEditor} from "./editors/ContainerTypeEditor";
import {TablePageLayout} from "../layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"

export const ContainerTypesPage = () => {
    const {
        containerTypesApi: {
            forceRefresh,
            data: types,
            loading: loadingTypes,
            error: error,
            resetApiError,
            updateItem: updateType,
            deleteItem: deleteType,
            refreshItems: refreshTypes
        }
    } = useGlobalApi()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [modalCreateContainerType, setModalCreateContainerType] = useState<boolean>(false)
    const columns: any[] = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'label', headerName: 'Nom', width: 150 },
        { field: 'rfidReference', headerName: 'Valeur RFID', width: 150 },
    ]
    return (
        <>
            <TablePageLayout
                title={"TYPE DE CONTENANT"}
                Editor={ContainerTypeEditor}
                forceRefreshData={forceRefresh}
                errorReference={error}
                resetError={resetApiError}
                loadingReference={loadingTypes}
            >
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    updateRow={updateType}
                    deleteRow={deleteType}
                    rows={types}
                    loading={loadingTypes}
                    columns={columns}
                    confirmDeleteText={"Attention, la suppression d'un type de contenant entraîne la perte de tous les contenants de ce type"}
                />
            </TablePageLayout>
        </>
    )
}