import React, {FC, useEffect, useState} from "react";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel
} from "@mui/x-data-grid"
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {FModal} from "./FModal";
import {Button} from "../inputs/Button";
import {formatDate} from "../../../api/static_vars";
import {Visibility} from "@mui/icons-material";
import {Identifiable} from "../../../interfaces/Identifiable";

interface GridValidRowModel extends GridRowModel, Identifiable {
    // Ajoutez d'autres propriétés nécessaires pour votre modèle de ligne ici
}

interface IFDataGrid {
    rows: any[],
    loading: boolean,
    ignoreDates?: boolean,
    disableEdit?: boolean,
    columns: GridColDef[],
    deleteRow?: (id: number) => Promise<void>,
    afterDelete?: () => void,
    updateRow?: <U extends Identifiable>(element: U) => Promise<U | undefined>,
    rowModesModel: GridRowModesModel,
    setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
    confirmDeleteText?: string,
    showMore?: (id: number) => void,
}

interface DeletedRow {
    label?: string;
    login?: string;
    internalOrderId?: string;
    identifier?: string;
    id: number;
}

export const FDataGrid: FC<IFDataGrid> = ({
    rowModesModel,
    setRowModesModel,
    rows,
    loading,
    ignoreDates,
    disableEdit,
    columns,
    updateRow,
    deleteRow,
    afterDelete,
    confirmDeleteText,
    showMore
}) => {
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);
    const [deletedRow, setDeletedRow] = useState<DeletedRow | undefined>(undefined);
    const returnFirstNonEmptyIdentifier = (row: DeletedRow) => {
        return row.label ?? row.login ?? row.internalOrderId ?? row.identifier ?? row.id;
    };
    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };
    const handleShowMore = (id: GridRowId) => () => {
        let rowId = id as number;
        if (showMore) showMore(rowId);
    };
    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };
    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };
    const handleDeleteClick = (id: GridRowId) => () => {
        let rowId = id as number;
        let row = rows.find(r => r.id == rowId);
        setDeletedRow(row);
        setConfirmDeleteModalOpen(true);
    };
    const processRowUpdate = async (newRow: GridValidRowModel, oldRow: GridValidRowModel): Promise<GridValidRowModel> => {
        if (!updateRow) return oldRow;
        let r = await updateRow(newRow);
        if (r !== undefined) {
            return newRow;
        } else {
            return oldRow;
        }
    };
    const confirmDelete = (e: React.MouseEvent) => {
        if (!deleteRow || deletedRow == undefined) return;
        deleteRow(deletedRow.id).then( r => {
            setConfirmDeleteModalOpen(false)
            setDeletedRow(undefined);
            if (afterDelete != null) {
                afterDelete();
            }
        })
    };
    const cancelDelete = () => {
        setConfirmDeleteModalOpen(false);
        setDeletedRow(undefined);
    };
    useEffect(() => {
        console.log(rows);
    }, [rows]);
    return (<div className="h-5/6 mr-2 ml-2">
        {confirmDeleteModalOpen && deletedRow != undefined &&
            <FModal className="bg-white w-2/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl"
                    canClose onClose={cancelDelete} title={"Confirmation"}>
                <div className={"w-full flex flex-col mt-4"}>
                    <p className="text-xl">Êtes-vous sûr de supprimer {returnFirstNonEmptyIdentifier(deletedRow)}</p>
                    {confirmDeleteText &&
                        <p className="p-5 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{confirmDeleteText}</p>
                    }
                </div>
                <div className={"w-full flex gap-2 mt-5 mb-5 "}>
                    <Button text={"Annuler"} onClick={cancelDelete}/>
                    <Button text={"Valider"} onClick={confirmDelete}/>
                </div>
            </FModal>
        }
        {columns && columns.length > 1 &&
            <DataGrid
                rows={rows}
                columns={[...columns,
                    {
                        field: 'createdAt',
                        headerName: 'Date de création',
                        type: 'dateTime',
                        width: 200,
                        valueGetter: formatDate
                    },
                    {
                        field: 'updatedAt',
                        headerName: 'Date de mise à jour',
                        type: 'dateTime',
                        width: 200,
                        valueGetter: formatDate
                    },
                    {
                        field: 'actions', headerName: 'Actions', type: 'actions', width: 100, cellClassName: 'actions',
                        getActions: ({id}: any) => {
                            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                            if (isInEditMode) {
                                return [
                                    <GridActionsCellItem
                                        icon={<SaveIcon/>}
                                        label="Save"
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                        onClick={handleSaveClick(id)}
                                    />,
                                    <GridActionsCellItem
                                        icon={<CancelIcon/>}
                                        label="Cancel"
                                        className="textPrimary"
                                        onClick={handleCancelClick(id)}
                                        color="inherit"
                                    />,

                                ];
                            }
                            const cellActions: JSX.Element[] = [];
                            if (showMore) {
                                cellActions.push(
                                    <GridActionsCellItem
                                        icon={<Visibility/>}
                                        label="Voir plus"
                                        onClick={handleShowMore(id)}
                                    />
                                );
                            }
                            if (updateRow) {
                                cellActions.push(
                                    <GridActionsCellItem
                                        icon={<EditIcon/>}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={handleEditClick(id)}
                                        color="inherit"
                                    />
                                );
                            }
                            if (deleteRow) {
                                cellActions.push(
                                    <GridActionsCellItem
                                        icon={<DeleteIcon/>}
                                        label="Delete"
                                        onClick={handleDeleteClick(id)}
                                        color="inherit"
                                    />
                                );
                            }


                            return cellActions;
                        }
                    }
                ]}
                columnVisibilityModel={{
                    id: false,
                    createdAt: !ignoreDates,
                    updatedAt: !ignoreDates,
                    actions: (showMore != undefined) ? true : !disableEdit
                }}
                loading={loading}
                editMode="row"
                processRowUpdate={processRowUpdate}
                rowModesModel={rowModesModel}
                disableRowSelectionOnClick
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'updatedAt', sort: 'desc' }],
                    },
                }}
                localeText={{noRowsLabel: 'Aucun données à afficher'}}
                pageSizeOptions={[10, 20, 50]}
                sx={{
                    width: '100%',
                    backgroundColor: 'rgb(203, 236, 249)',
                    '& .MuiDataGrid-row:hover': {
                        color: '#e2effc',
                        backgroundColor: '#0e5896'
                    },
                }}
            />}
    </div>);
};
