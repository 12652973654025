import '../fab_ui.css'
import React, {ChangeEvent, FC} from 'react'
import {IExtendedNumberInput} from '../interfaces/inputs/IExtendedInput';

export const NumberInput: FC<IExtendedNumberInput> = ({value, defaultValue, className, onChange, label, setterChange, dataCustom}) => {
    //const uuid = randomUUID()
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (setterChange) {
            setterChange(Number.parseInt(e.currentTarget.value))
        }
        if (onChange) {
            onChange(e);
        }
    };
    return(
        <div className={className}>
            {label && <label className="w-1/3 text-left">{label}</label>}
            <input
                type='number'
                min="0"
                onChange={onChangeHandler}
                defaultValue={defaultValue}
                value={value}
                data-custom={dataCustom}
                className={`w-2/3 fab_input`}
            />
        </div>
    )
}