import '../fab_ui.css'
import React, {ChangeEvent, FC} from 'react'
import { IExtendedTextInput } from '../interfaces/inputs/IExtendedInput'

export const TextInput: FC<IExtendedTextInput> = ({value, disabled, defaultValue, className, onChange, label, setterChange, dataCustom, maxLength, password, key}) => {
    //const uuid = randomUUID()
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (setterChange) {
            setterChange(e.currentTarget.value)
        }
        if (onChange) {
            onChange(e)
        }
    }

    return(
        <div className={className}>
            {label && <div className="w-1/3 text-left m-auto border-b pb-2">{label}</div>}
            <input
                disabled={disabled}
                maxLength={maxLength ?? -1}
                type={password ? "password" : "text"}
                data-custom={dataCustom}
                onChange={onChangeHandler}
                value={value}
                className={`w-2/3 fab_input`}/>
        </div>
    )
}