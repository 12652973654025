import {Button} from "../../ui/inputs/Button"
import React from "react"
import {
    GridRowModesModel, GridRowParams,
} from "@mui/x-data-grid"
import {FDataGrid} from "../../ui/elements/FDataGrid"
import {useFabApp} from "../../../hooks/useFabApp"
import {TablePageLayout} from "../layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"

export const ApplicationDevicesPage = () => {
    const { applicationDevicesApi : {
        forceRefresh,
        data: devices,
        loading,
        error,
        getItemById: getDeviceById,
        deleteItem: deleteDevice,
        updateItem: updateDevice,
        refreshItems: refreshDevices,
        resetApiError
    } } = useGlobalApi()
    const {user} = useFabApp()
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const switchAuthorization = (id: number) => {
        if (!user?.superUser) return
        let foundDevice = getDeviceById(id)
        if (!foundDevice) return
        foundDevice.authorized = !foundDevice.authorized
        updateDevice(foundDevice).then()
    }
    const columns: any[] = [{field: 'id', headerName: '#', width: 50},
        {field: 'label', headerName: 'Nom (Optionnel)', type: 'string', width: 100, editable: true},
        {field: 'deviceIdentifier', headerName: 'Identifiant', type: 'string', width: 150, editable: true},
        {field: 'model', headerName: 'Modèle Lecteur', type: 'string', width: 100, editable: true},
        {field: 'fabtracerVersion', headerName: 'FABTRACER', type: 'string', width: 150, editable: true},
        {field: 'androidVersion', headerName: 'ANDROID', type: 'string', width: 150, editable: true},
        {field: 'buildVersion', headerName: 'BUILD', type: 'string', editable: true, width: 200},
        {
            field: 'invert_authorization', headerName: '-', type: 'actions', width: 200, cellClassName: 'actions',
            getActions: (params: GridRowParams) => {
                return [
                    <Button
                        specificColor={params.row.authorized ? "!bg-fab_light_red-400" : "!bg-fab_mint_green-200"}
                        text={params.row.authorized ? "Révoquer l'accès" : "Autoriser l'accès"}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            //e.stopPropagation()
                            e.currentTarget.parentElement?.parentElement?.parentElement?.focus()
                            switchAuthorization(params.row.id)
                        }}
                    />
                ]
            }
        }]
    return (
        <>
            <TablePageLayout
                title={"TERMINAUX"}
                forceRefreshData={forceRefresh}
                errorReference={error}
                resetError={resetApiError}
                loadingReference={loading}
            >
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    deleteRow={deleteDevice}
                    updateRow={updateDevice}
                    rows={devices}
                    loading={loading}
                    columns={columns}
                    showMore={() => {
                    }}/>
            </TablePageLayout>
        </>
    )
}