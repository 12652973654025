import {IFModal} from "../../ui/interfaces/elements/IFModal"
import React, {FC, useState} from "react"
import {FabOrder} from "../../../interfaces/FabOrder"
import {useFabApp} from "../../../hooks/useFabApp"
import {FModal} from "../../ui/elements/FModal"
import {Button} from "../../ui/inputs/Button"
import {PlusCircleIcon, XCircleIcon} from "@heroicons/react/24/solid"
import {Field, FormGenerator} from "../../ui/elements/FabForm"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"

export const EncodingFabOrderEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        recoEncodingFabOrderApi: {loading, addItem: addFabOrder, error},
        productReferencesApi: {data: productReferences}
    } = useGlobalApi()

    const integrationStatus = [
        { id: 1, label: "NEUF(N)", value: 1},
        { id: 2, label: "RETROFIT(R)", value: 2}
    ]
    const {companyChosen, siteChosen} = useFabApp()
    const [creationDone, setCreationDone] = useState<boolean>(false)
    const [newFabOrder, setNewFabOrder] = useState<FabOrder>({} as FabOrder)
    const newOrder = () => {
        setCreationDone(false)
    }
    const fields: Field[] = [
        {label: 'Quantité', name: 'quantity', type: 'number', required: true},
        {label: 'Lot (3 caractère)', name: 'batch', type: 'text', required: true, maxLength: 3},
        {
            label: 'État d\'intégration',
            name: 'statusIntegrationValueId',
            type: 'autocomplete',
            required: true,
            data: integrationStatus
        },
    ]
    const handleFormSubmit = (data: any) => {
        const fabOrderToSend: FabOrder = {
            id: 0,
            internalOrderId: "",
            closed: false,
            siteId: siteChosen!.id,
            Infos: [{
                id: 0,
                tagInfoTypeId: 1,
                tagInfoTypeValueId: data.statusIntegrationValueId
            }],
            Lines: [{
                id: 0,
                quantity: data.quantity,
                quantityDone: 0,
                batch: data.batch,
                productCategoryId: 0,
                productReferenceId: 0,
                containerTypeId: 0,
                customerId: 0,
                fabOrderId: 0,
                Movements: []
            }]
        }
        fabOrderToSend.siteId = siteChosen?.id ?? 0
        addFabOrder(fabOrderToSend).then(r => {
            if (error || !r) return
            setNewFabOrder(r)
            setCreationDone(true)
        })
    }
    return (
        <FModal
            className="fab_editor_modal h-2/3"
            canClose onClose={onClose} title={`Création d'un bon de préparation`}>
            <div className="w-full">
                <span>Société : <b>{companyChosen?.label}</b></span>
            </div>
            <div className="w-full">
                <span>Site : <b>{siteChosen?.label}</b></span>
            </div>
            {creationDone ? <div className={"w-full m-auto p-1 mt-10"}>
                    <span className={"text-2xl font-bold"}>{newFabOrder.internalOrderId} crée</span>
                    <Button onClick={newOrder} leftIcon={<PlusCircleIcon/>} loading={loading}
                            text={"Ajouter un ordre de fabrication"}
                            className={"mt-2 mb-1 w-1/2"}/>
                    <Button onClick={onClose} leftIcon={<XCircleIcon/>} loading={loading}
                            text={"Fermer"}
                            className={"mt-2 mb-1 w-1/2"}/>
                </div>
                :
                <FormGenerator loading={loading} fields={fields} onSubmit={handleFormSubmit} apiError={error?.message}/>
            }
        </FModal>)
}