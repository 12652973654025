import '../fab_ui.css'
import React, {FC, useEffect, useState} from 'react';
import {IButton} from "../interfaces/inputs/IButton";
import {useNavigate} from "react-router-dom";
import {useFabApp} from "../../../hooks/useFabApp";
export const FNavButton:
    FC<{processType?: number, processId?: number} & IButton> =
    ({processType, processId, text, leftIcon, onClick, route}) => {
        const navigate = useNavigate();
        const [active, setActive] = useState<boolean>();
        const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (onClick) {
                onClick(e);
            }
            if (route) {
                navigate(route);
            }
        }
        useEffect(() => {
            if (route) {
                const routeWithoutSlashesAndDigits = route.replace(/[\/0-9]/g, '');
                const urlWithoutSlashesAndDigits = document.URL.replace(/[\/0-9]/g, '');
                const regex = new RegExp(`${routeWithoutSlashesAndDigits}($|[^a-zA-Z0-9_])`);
                const match = urlWithoutSlashesAndDigits.match(regex);
                if (match != null) {
                    setActive(true);
                    return;
                }
            }

            setActive(false)
        }, [navigate]);
        return (
            <button
                onClick={handleClick}
                className={`w-full flex duration-150 m-2 cursor-pointer ${active ? "bg-fab_congress-blue-500 text-white shadow-md" : "hover:bg-fab_congress-blue-500 hover:shadow-md"} `}>
                <div className="rounded-sm flex p-1">
                    {leftIcon}
                    <div className="h-max-full pr-1 pl-1">{text}</div>
                </div>
            </button>
        )
    }