import React, {useEffect, useState} from "react"
import { GridColDef, GridRowModesModel, GridRowParams } from "@mui/x-data-grid";
import {FabOrder} from "../../../interfaces/FabOrder"
import {FDataGrid} from "../../ui/elements/FDataGrid"
import {
    Check,
    HourglassEmpty,
    QuestionMark,
    TapAndPlay
} from "@mui/icons-material"
import {EncodingFabOrderEditor} from "../editors/EncodingFabOrderEditor"
import {FCheckBox} from "../../ui/inputs/FCheckBox"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {TablePageLayout} from "../../base/layout/TablePageLayout"
import { Button } from "../../ui/inputs/Button";

export const EncodingPage = () => {
    const {
        recoEncodingFabOrderApi: {
            data: orders,
            loading,
            error,
            resetApiError,
            updateItem: updateOrder,
            refreshItems: refreshOrders,
            forceRefresh: forceRefreshOrders,
            deleteItem: deleteFabOrder
        }
    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<FabOrder[]>(orders)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [filterStates, setFilterStates] = React.useState({
        notStarted: true,
        progress: true,
        closed: true,
    })
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])

    useEffect(() => {
        forceRefreshOrders()
        setColumns(
            [
                {field: 'id', headerName: '#', editable: false, hideable: true},
                {field: 'internalOrderId', headerName: 'Identifiant', editable: true, width: 125},
                {
                    field: 'statusCode',
                    headerName: 'Status',
                    editable: true,
                    width: 300,
                    renderCell: (params: any) => {
                        switch (params.formattedValue) {
                            case "NSTA" :
                                return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                            case "STAR" :
                                return <span className={"font-bold"}><TapAndPlay/> En cours</span>
                            case "DONE" :
                                return <span className={"font-bold"}><Check/>Cloturé</span>
                            default :
                                return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                        }

                    }
                },
                {
                    field: 'quantity_state',
                    headerName: 'Etat Quantité',
                    editable: true,
                    width: 150,
                    renderCell: (params: any) => {
                        if (params.row.Lines.length == 0) return "N/A"
                        return `${params.row.Lines[0].quantityDone}/${params.row.Lines[0].quantity}`
                    }
                },
                {
                    field: 'batch_header',
                    headerName: 'Lot',
                    editable: false,
                    width: 150,
                    renderCell: (params: any) => {
                        if (params.row.Lines.length == 0) return "N/A"
                        return params.row.Lines[0].batch
                    }
                },
                {
                    field: 'state_integration',
                    headerName: 'État d\'intégration',
                    editable: false,
                    width: 150,
                    renderCell: (params: any) => {
                        return params.row.Infos[0]?.InfoValue?.label ?? "N/A"
                    }
                },
                {
                    field: 'close_order', headerName: '-', type: 'actions', width: 200, cellClassName: 'actions',
                    getActions: (params: GridRowParams) => {
                        const fabOrder = params.row
                        return [
                            <Button
                              disabled={fabOrder.closed}
                              text={"Cloturer l'ordre"}
                              onClick={() => {
                                  fabOrder.closed = true
                                  updateOrder(fabOrder).then( (r) => {
                                      forceRefreshOrders()
                                  })
                              }}
                            />
                        ]
                    }
                }
            ]
        )
    }, [])
    const toggleNotStarted = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, notStarted: !prevState.notStarted}))
    }
    const toggleInProgress = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, progress: !prevState.progress}))
    }
    const toggleDone = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, closed: !prevState.closed}))
    }
    useEffect(() => {
        let filteredData: FabOrder[] = []
        if (filterStates.notStarted) {
            filteredData.push(...orders.filter((value) => value.Lines[0]?.quantityDone == 0 && !value.closed))
        }
        if (filterStates.progress) {
            filteredData.push(...orders.filter((value) => value.Lines[0]?.quantityDone > 0 && !value.closed))
        }
        if (filterStates.closed) {
            filteredData.push(...orders.filter((value) => value.closed))
        }
        setDataFiltered(filteredData)
        setDataLoading(false)
    }, [filterStates, orders])
    return (<>
        <TablePageLayout
            title={"ORDRE D'ENCODAGE"}
            Editor={EncodingFabOrderEditor}
            forceRefreshData={forceRefreshOrders}
            errorReference={error}
            resetError={resetApiError}
            loadingReference={loading}
            checkboxs={<>
                <FCheckBox
                    label={'En attente'}
                    onChange={toggleNotStarted}
                    state={filterStates.notStarted}
                />
                <FCheckBox
                    label={'En cours'}
                    onChange={toggleInProgress}
                    state={filterStates.progress}
                />
                <FCheckBox
                    label={'Cloturés'}
                    onChange={toggleDone}
                    state={filterStates.closed}
                />
            </>}
        >
            <FDataGrid
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                rows={dataFiltered}
                loading={dataLoading}
                deleteRow={deleteFabOrder}
                columns={columns}/>
        </TablePageLayout>
    </>)
}