import React, {createContext, useContext, ReactNode, useState} from 'react'
import {useApi} from "../api/_useApi"
import {Role} from "../interfaces/Role"
import {User} from "../interfaces/User"
import {FabOrder, FabOrderLine, FabOrderLineMovement} from "../interfaces/FabOrder"
import {ISite} from "../interfaces/ISite"
import {ICompany} from "../interfaces/ICompany"
import {DashboardInfos} from "../interfaces/DashboardInfos"
import {Tag} from "../interfaces/Tag"
import {ApplicationDevice} from "../interfaces/ApplicationDevice"
import {ContainerType} from "../interfaces/Container"
import {ProductCategory, ProductReference} from "../interfaces/Product"
import {ICustomer} from "../interfaces/ICustomer"
import {IStatusCode} from "../interfaces/processes/IStatusCode"
import {useDashboardApi} from "../api/base_apis"
import { TagInfoType } from "../interfaces/TagInfoType";

// Interface pour tous les API hooks
type GlobalApiContextType = {
    companiesApi: ReturnType<typeof useApi<ICompany>>;
    sitesApi: ReturnType<typeof useApi<ISite>>;
    customersApi: ReturnType<typeof useApi<ICustomer>>;
    tagsApi: ReturnType<typeof useApi<Tag>>;
    fabOrdersApi: ReturnType<typeof useApi<FabOrder>>;
    fabOrderLinesApi: ReturnType<typeof useApi<FabOrderLine>>;
    fabOrderLineMovementsApi: ReturnType<typeof useApi<FabOrderLineMovement>>;
    siteMovementsApi: ReturnType<typeof useApi<FabOrderLineMovement>>;
    rolesApi: ReturnType<typeof useApi<Role>>;
    usersApi: ReturnType<typeof useApi<User>>;
    applicationDevicesApi: ReturnType<typeof useApi<ApplicationDevice>>;
    containerTypesApi: ReturnType<typeof useApi<ContainerType>>;
    productCategoriesApi: ReturnType<typeof useApi<ProductCategory>>;
    productReferencesApi: ReturnType<typeof useApi<ProductReference>>;
    statusCodesApi: ReturnType<typeof useApi<IStatusCode>>;
    taggedContainersApi: ReturnType<typeof useApi<Tag>>;
    recoEncodingFabOrderApi: ReturnType<typeof useApi<FabOrder>>;
    dashboardApi: ReturnType<typeof useApi<DashboardInfos>>;
    tagInformationApi: ReturnType<typeof useApi<TagInfoType>>;
}

// Création du contexte
const GlobalApiContext = createContext<GlobalApiContextType | undefined>(undefined)

// Le GlobalApiProvider qui englobe toutes les APIs
export const GlobalApiProvider = ({children}: { children: ReactNode }) => {
    const [count, setCount] = useState<number>(0)

    const companiesApi = useApi<ICompany>({endpoint: 'web/companie'})
    const sitesApi = useApi<ISite>({endpoint: 'web/site'})
    const customersApi = useApi<ICustomer>({endpoint: 'web/customer'})
    const tagsApi = useApi<Tag>({endpoint: 'web/tag'})
    const fabOrdersApi = useApi<FabOrder>({
        endpoint: 'web/fab_order',
        siteDependant: true,
        fabOrderTypeDependant: true
    })
    const fabOrderLinesApi = useApi<FabOrderLine>({
        endpoint: 'web/fab_order_line',
        specificIdentifierDependant: true,
    })
    const fabOrderLineMovementsApi = useApi<FabOrderLineMovement>({
        endpoint: 'web/fab_order_line_movement',
        specificIdentifierDependant: true,
    })
    const siteMovementsApi = useApi<FabOrderLineMovement>({
        endpoint: 'web/site/movement',
        siteDependant: true
    })
    const rolesApi = useApi<Role>({endpoint: 'web/role'})
    const usersApi = useApi<User>({endpoint: 'web/user'})
    const applicationDevicesApi = useApi<ApplicationDevice>({endpoint: 'web/application_device'})
    const containerTypesApi = useApi<ContainerType>({endpoint: 'web/container_type'})
    const productCategoriesApi = useApi<ProductCategory>({endpoint: 'web/product_categorie'})
    const productReferencesApi = useApi<ProductReference>({endpoint: 'web/product_reference'})
    const statusCodesApi = useApi<IStatusCode>({endpoint: 'web/status_code'})
    const taggedContainersApi = useApi<Tag>({
        endpoint: 'web/tagged_container',
        siteDependant: true,
    })
    //SABC
    const recoEncodingFabOrderApi = useApi<FabOrder>({
        endpoint: 'reco/encoding_order',
        siteDependant: true,
    })
    const dashboardApi = useApi<DashboardInfos>({
        endpoint:'web/dashboard',
        siteDependant: true
    })
    const tagInformationApi = useApi<TagInfoType>({
        endpoint: 'web/tag_info_type'
    })
    return (
        <GlobalApiContext.Provider value={{
            companiesApi,
            sitesApi,
            customersApi,
            tagsApi,
            fabOrdersApi,
            fabOrderLinesApi,
            fabOrderLineMovementsApi,
            siteMovementsApi,
            rolesApi,
            usersApi,
            applicationDevicesApi,
            containerTypesApi,
            productCategoriesApi,
            productReferencesApi,
            statusCodesApi,
            taggedContainersApi,
            recoEncodingFabOrderApi,
            dashboardApi,
            tagInformationApi
        }}>
            {children}
        </GlobalApiContext.Provider>
    )
}

// Hook pour accéder facilement aux APIs
export const useGlobalApi = (): GlobalApiContextType => {
    const context = useContext(GlobalApiContext)
    if (!context) {
        throw new Error('useGlobalApi must be used within a GlobalApiProvider')
    }
    return context
}