import { createContext, useContext } from 'react';
import {ISite} from "../interfaces/ISite";
import {ICompany} from "../interfaces/ICompany";
import {User} from "../interfaces/User";
import {IEncodingProcess} from "../interfaces/processes/IEncodingProcess";
import {IStatusAssignmentProcess} from "../interfaces/processes/IStatusAssignmentProcess";
import {IVerificationProcess} from "../interfaces/processes/IVerificationProcess";

export type FabAppContextProps = {
  isLoading: boolean,
  gotToken: boolean,
  isAuthenticated: boolean,
  user: User | null,
  error?: string,
  siteChosen: ISite | null;
  specificIdentifierChosen: number | null;
  companyChosen: ICompany | null,
  loginWithRedirect?: (loginRedirect: string) => void,
  handleRedirectCallback: (...p: any) => any,
  notifications: Notification[],
  addNotification: (notifications: Notification) => void,
  setIsAuth: (state: boolean) => void,
  setUser: (user: User | null) => void,
  setSiteChosen: (site: ISite | null) => void,
  setSpecificIdentifierChosen: (id: number | null) => void
  setCompanyChosen: (company: ICompany | null) => void
};

export const FabAppContext = createContext<FabAppContextProps>({
  isLoading: false,
  gotToken: false,
  isAuthenticated: false,
  user: null,
  error: '',
  siteChosen: null,
  specificIdentifierChosen: null,
  companyChosen: null,
  handleRedirectCallback: () => {},
  notifications: [],
  addNotification: () => {},
  setIsAuth: () => {},
  setUser: () => {},
  setSiteChosen: () => {},
  setSpecificIdentifierChosen: () => {},
  setCompanyChosen: () => {}
});

export const useFabApp = (): Readonly<FabAppContextProps> => useContext(FabAppContext);