import { IFAutoComplete } from "../interfaces/inputs/IFAutoComplete";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {Identifiable} from "../../../interfaces/Identifiable";

export function FAutoComplete<T extends Identifiable>({className, label, data, dataCustom, onSelectionChange, valueId }: IFAutoComplete<T>) {
    const [value, setValue] = useState<T>()
    const onLocalSelectionChange = (e: SyntheticEvent<Element, Event>, newValue: T | null) => {
        if (dataCustom != undefined) {
            onSelectionChange({e, newValue, dataCustom})
        } else {
            onSelectionChange({e, newValue})
        }
    }

    useEffect( () => {
        let value = data.find(i => i.id == valueId)
        console.log(`Search ${valueId}`)
        console.log(value)
        if (value != undefined) setValue(value)
        }
    , [valueId])

    return (
        <div className={className}             data-custom={dataCustom}>
            {label && <div className="w-1/3 text-left m-auto border-b pb-2">{label}</div>}
        <Autocomplete
            options={data}
            data-custom={dataCustom}
            value={value}
            className="fab_input w-2/3 !border-transparent"
            onChange={onLocalSelectionChange}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.label}
                </li>
            )}
            renderInput={(params) => <TextField key={params.id} {...params}
                                                variant={'standard'}/>}/>
        </div>
    )
}