import {FDataGrid} from "../../ui/elements/FDataGrid";
import React, {useEffect, useState} from "react";
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {TablePageLayout} from "../layout/TablePageLayout"

export const SiteMovementsPage = () => {
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const {fabOrdersApi:{getItemById: getOrder, forceRefresh: forceRefreshOrders, data: orders}, siteMovementsApi : {forceRefresh, data: movements, error, resetApiError, loading}} = useGlobalApi();
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])

    useEffect(() => {
        forceRefreshOrders()
        forceRefresh()
    }, []);
    useEffect(() => {
        setColumns([
            {field: 'id', headerName: '#', width: 50},
            {
                field: 'Tag', headerName: 'EPC', width: 200, valueGetter: (params: any) => params ? params.rfidValue  : ''
            },
            {field: 'process', headerName: 'Procédure', width: 200, renderCell: () => {
                return "Encodage"
                } },
            {
                field: 'fabOrderId',
                headerName: 'Ordre',
                width: 300,
                renderCell: (params: any) => {
                    const label = getOrder(params.row.Tag?.lastFabOrderId)?.internalOrderId
                    return label ?? "N/A"

                }
            },
            {field: 'User', headerName: 'Utilisateur', width: 450, valueGetter: (params: any) => params ? params.mobileLogin: '' },
        ])
    }, [movements, orders]);
    return (
        <>
            <TablePageLayout
                title={"MOUVEMENTS"}
                forceRefreshData={forceRefresh}
                errorReference={error}
                resetError={resetApiError}
                loadingReference={loading}
                >
                <FDataGrid
                    disableEdit
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    rows={movements}
                    loading={loading}
                    columns={columns}/>
            </TablePageLayout>
        </>
    )
}