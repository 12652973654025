import {useApi} from "./_useApi";
import {FabOrderType} from "../interfaces/FabOrderType";
import {Role} from "../interfaces/Role";
import {User} from "../interfaces/User";
import {FabOrder, FabOrderLine, FabOrderLineMovement} from "../interfaces/FabOrder";
import {ISite} from "../interfaces/ISite";
import {ICompany} from "../interfaces/ICompany";
import {EncodingFormat} from "../interfaces/EncodingFormat";
import {DashboardInfos} from "../interfaces/DashboardInfos";
import {Tag} from "../interfaces/Tag";
import {ApplicationDevice} from "../interfaces/ApplicationDevice";
import {ContainerType} from "../interfaces/Container";
import {ProductCategory, ProductReference} from "../interfaces/Product";
import {ICustomer} from "../interfaces/ICustomer";
import {IEncodingProcess} from "../interfaces/processes/IEncodingProcess";
import {IStatusAssignmentProcess} from "../interfaces/processes/IStatusAssignmentProcess";
import {IStatusCode} from "../interfaces/processes/IStatusCode";
import {IVerificationProcess} from "../interfaces/processes/IVerificationProcess";
import {Simulate} from "react-dom/test-utils";

export function useCompaniesApi() {
    return useApi<ICompany>( {
        endpoint: 'web/companie',
        siteDependant: false,
    })
}
export function useDashboardApi() {
    return useApi<DashboardInfos>({
        endpoint:'web/dashboard',
        siteDependant: true
    })
}